import * as React from "react"
import { Navbar } from "react-bootstrap"
import { useState, useEffect } from "react"
import { handleEnergy, handleFire } from "../services/api"
import NavBar from "../components/navbar"
import { isLoggedIn, refreshToken, getUser } from "../services/auth"
import Button from "react-bootstrap/Button"

export default function Help() {
  return (
    <div>
      <NavBar />
      <div className="helpcenter">
        <h1> 帮助</h1>
        <p>
          1.
          比赛期间，参赛选手会获得家园系统网站的指定账号及密码，选手严禁向他人泄露账号及密码。登陆家园系统网站会跳转至本季比赛大地图，选手非特殊情况不可挖掘或采集非其所在部落区域；
        </p>
        <p>
          2. 比赛期间，每日将依据北京时间被划分为12个时间段，具体划分见下表：
          白昼时段 6:00-8:59 9:00-11:59 12:00-14:59 15:00-17:59 18:00-20:59
          21:00-23:59 黑夜时段 0:00-0:59 1:00-1:59 2:00-2:59 3:00-3:59 4:00-4:59
          5:00-5:59
          每个时间段选手拥有1点行动点，即在白昼时段每3小时获得1个行动点，黑夜时段每1小时获得1个行动点；
        </p>
        <p>
          2.1
          比赛期间，选手可消耗一个行动点对可活动区域内一个坐标格进行一次「采集」、「探索」或「特殊行为」的操作指令，不可在同一时间段同时进行「采集」和「探索」指令，网站会对选手的每次地图操作结果进行反馈；
          2.1.1
          若选手在一时段内未使用行动点，行动点作超时作废处理，无法累积保留至下一时间段；
          2.1.2「采集」必须在白天时间段进行。选手消耗1行动点收集其可活动区域所含物资及食物；
          2.1.4
          「探索」可在任意时间段进行。选手消耗一个行动点查验其可活动区域的一个坐标格的优势有否情况。
        </p>
      </div>
    </div>
  )
}
